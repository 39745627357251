import React from 'react';
import { Box, Button, Popover, Typography } from '@material-ui/core';

import { DateConversions } from "../utils/DateConversions";

/**
 * Popup Dialog to prompt the user to select how to handle a recurring slot.  The user can choose to modify/delete just this slot, this and all future slots, or all slots in the series.
 * Caller should pass the following props:
 * 
 * isOpen (boolean): True if the dialog is open, false otherwise
 * slot (Slot): The slot being modified or deleted
 * timezone (string): The timezone of the slot
 * recurringActiveCount: the number of active slots in the series
 * isDeletingSlot (boolean): True if the slot is being deleted, false if it is being modified
 * willRegenerateRecurrence (boolean): True if the slot will cause all the future slots to be regenerated because the time or recurrence settings have changed
 * justThisOneSelected (function): Callback function called when the user selects "Just This One"
 * thisAndFutureSelected (function): Callback function called when the user selects "This and All Future"
 * allSelected (function): Callback function called when the user selects "All"
 * cancelSelected (function): Callback function called when the user selects "Cancel"
 * 
 */
export function RecurrenceQuestionDialog(props) { 

    if (!props.slot)
        return null;

    const type = props.slot.type;
    const deleteWord = props.slot.type === "Event" ? "Delete/Cancel " : "Delete ";
    const slotInPast = props.slot.start < DateConversions.now(props.timezone);
    const title = (props.isDeletingSlot ? deleteWord : "Change ") + " Recurring " + (slotInPast ? "Past " : "") + type;
    


    let maxWidth = window.innerWidth * 0.80;
    if (maxWidth > 1100)
        maxWidth = 1100;

    const buttons = [
        {buttonText: "Change Just This One", callback: props.justThisOneSelected, color: props.isDeletingSlot ? 'red' : 'green'},
        {buttonText: "Change this and All Subsequent", callback: props.thisAndFutureSelected, color: props.isDeletingSlot ? 'red' : 'blue'},
        {buttonText: "All", callback: props.allSelected, color: props.isDeletingSlot ? 'red' : 'blue'},
        {buttonText: "Cancel", callback: props.cancelSelected, description: "Do not make any changes.", color: 'black'}
    ];
    

    if (props.isDeletingSlot) {
        const cancelText = props.slot && props.slot.type === "Event" ? " Events with attendees will be CANCELLED, others deleted." : "";

        buttons[0].buttonText = props.slot.state === "CREATED" ? "Delete Just This One" : "Cancel Just This One";
        buttons[1].buttonText = deleteWord + "this and All Subsequent";
        buttons[2].buttonText = deleteWord + "the Entire Series";
        buttons[0].description = "Only this " + type + " will be " + (props.slot.state === "CREATED" ? "deleted." : "cancelled.");
        buttons[1].description = deleteWord + "this and all subsequent " + type + "s in the recurring series." + cancelText;
        buttons[2].description = deleteWord + "all " + type + "s in the series." + cancelText;
    }
    else {
        const cancelText = props.slot && props.slot.type === "Event" ? " Active Events in this series with attendees will be cancelled. The attendees will NOT be carried over to regenerated Events." : "";

        buttons[0].description = "This " + type + " will be modified and removed from the recurring series. The remaining " + type + "s in the recurring series will be unchanged. You can edit it again to add a different recurrence pattern if you wish.";
        if (props.willRegenerateRecurrence) {
            const str = "Modify this and all subsequent " + type + "s  in the recurring series. The " + type + "s will be regenerated into a new recurrence series based on the start time and recurrence settings." 
            buttons[1].description = <span>{str}<span style={{color: 'red'}}>{cancelText}</span></span>;
        }
        else
            buttons[1].description = "Modify this and all subsequent " + type + "s  in the recurring series.";
        buttons[2].description = "Modify all " + type + "s  in the recurring series.";
    }

    if (props.willRegenerateRecurrence)
        buttons[2] = null;      // we don't allow modifying the entire series if the series will be regenerated

    const regenerateMessage = props.willRegenerateRecurrence ? " The times and/or recurrence settings have also changed." : "";

        
    const message = "This " + type + " is part of a recurring series." + regenerateMessage + " What would you like to do?";

    const pastMessage = slotInPast ? ("You are " + (props.isDeletingSlot ? "deleting/cancelling" : "modifying") + " " + type + "(s) that have already occurred. Make sure you really want to change past " + type + "s.") : null;

    return (
        <Popover open={props.isOpen}
            anchorReference="anchorPosition"
            anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
            transformOrigin={{vertical: 'center', horizontal: 'center'}}>
        
            <Box style={{margin: 20, width: maxWidth}}>
                <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 20}}>{title}</Typography>  

                <Typography variant='body2' style={{marginBottom: 20}}>{message}</Typography>
               
                {buttons.map((button, index) => (
                    button === null ? null :
                        <div key={index} style={{display: 'flex', justifyContent: 'left', gap: 20, alignItems: 'center', marginTop: 20}}>
                            <Button style={{width: 250, flex: '0 0 30%', color: button.color}} onClick={button.callback} color="primary" variant="outlined">{button.buttonText}</Button>
                            <Typography variant="body2" style={{color: 'gray', fontStyle: 'italic'}}>{button.description}</Typography>
                        </div>
                ))}
               
               {pastMessage ?
                    <Typography variant='body1' align='center' style={{marginTop: 20, fontWeight: 'bold', color: 'orange'}}>{pastMessage}</Typography>
                    : null
               }
               {props.recurringActiveCount > 0 ?
                    <Typography variant='body1' align='center' style={{marginTop: 20, fontWeight: 'bold', color: 'orange'}}>{"There are " + props.recurringActiveCount + " Active Events with attendees upcoming!"}</Typography>
                    : null
                }

            </Box>

        </Popover>
    );

}