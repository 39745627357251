import React from 'react';
import { Box, Button, Popover, Typography } from '@material-ui/core';


/**
 * Popup Dialog to prompt the user to select how to modify signup for a recurring Event  The user can choose to modify/delete just this slot or this and all future slots.
 * Caller should pass the following props:
 * 
 * isOpen (boolean): True if the dialog is open, false otherwise
 * okSelected (function): Callback function called when the user selects "Just This One", passes false as a parameter
 * okSelected (function): Callback function called when the user selects "This and All Future", passes true as a parameter
 * cancelSelected (function): Callback function called when the user selects "Cancel"
 * 
 */
export function RecurrenceSignupQuestionDialog(props) { 

    let maxWidth = window.innerWidth * 0.80;
    if (maxWidth > 1100)
        maxWidth = 1100;

    const buttons = [
        {buttonText: "Change Just This One", callback: () => props.okSelected(false), color: 'green', description: "Signup configuration for only this Event will be modified."},
        {buttonText: "Change this and All Subsequent", callback: () => props.okSelected(true), color: 'blue', description: "Modify Signup configuration for this and all subsequent Events in the recurring series, not including those that are cancelled."},
        {buttonText: "Cancel", callback: props.cancelSelected, description: "Do not make any changes.", color: 'black'}
    ];
    

    const message = "This Event is part of a recurring series. What would you like to do?";

    return (
        <Popover open={props.isOpen}
            anchorReference="anchorPosition"
            anchorPosition={{left: window.innerWidth/2, top: window.innerHeight/2}}
            transformOrigin={{vertical: 'center', horizontal: 'center'}}>
        
            <Box style={{margin: 20, width: maxWidth}}>
                <Typography variant="subtitle1" style={{fontWeight: 'bold', marginBottom: 20}}>Save Signup for Recurring Event</Typography>  

                <Typography variant='body2' style={{marginBottom: 20}}>{message}</Typography>
               
                {buttons.map((button, index) => (
                    button === null ? null :
                        <div key={index} style={{display: 'flex', justifyContent: 'left', gap: 20, alignItems: 'center', marginTop: 20}}>
                            <Button style={{width: 250, flex: '0 0 30%', color: button.color}} onClick={button.callback} color="primary" variant="outlined">{button.buttonText}</Button>
                            <Typography variant="body2" style={{color: 'gray', fontStyle: 'italic'}}>{button.description}</Typography>
                        </div>
                ))}
            </Box>

        </Popover>
    );

}