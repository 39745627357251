import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { Paper, Grid, Typography, Button, Checkbox, Tooltip, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReplyIcon from '@material-ui/icons/Reply';

import { Global, Constructors } from '../models/Global'
import { Service } from '../models/Service'
import { Resource } from '../models/Resource'
import { Availability } from '../models/Availability';
import { Block } from '../models/Block'
import { CalEvent } from '../models/CalEvent'
import { Booking } from '../models/Booking'
import { Slot } from '../models/Slot'
import { Recurrence } from '../models/Recurrence'

import { RestComponent, ListSelectPopover } from 'react-frontend-utils' 
import { NewEditResourcePopover } from '../components/NewEditResourcePopover';
import { NewSlotSelectionPopover } from '../components/NewSlotSelectionPopover';
import BookingCalendar  from '../components/BookingCalendar';
import { RecurrenceQuestionDialog } from '../components/RecurrenceQuestionDialog';
import { CalendarOptionsPopover } from '../components/CalendarOptionsPopover';
import { EditPatronPopover } from '../components/EditPatronPopover';
import { deleteSlotConfirmation } from '../components/DeleteSlotConfirmation';

import { ThemeColors } from '../Theme';
import { EditSlot } from '../components/EditSlot';
import { AssignResourcesPopover } from '../components/AssignResourcesPopover';
import { DateConversions } from '../utils/DateConversions';
import { SlotSelectionPopover } from '../components/SlotSelectionPopover';
import { ResourceColorActions } from '../components/ResourceColorActions';
import { CategoryGroup } from '../components/CategoryGroup';


/**
 * The CalendarPage is the main page for the Booking Calendar. It displays the calendar, and allows the user to add, edit, and delete slots, and manage resources.
 * 
 * The following props are passed:
 *  highlightedSlotId: the id of the slot to highlight
 *  manageServiceCallback: a callback that takes a Resource id of a Service and switches to the ManageServicePage
 *  manageEventSignupCallback: a callback that takes an Event id and switches to the EventSignupPage
 * 
 */
export class CalendarPage extends RestComponent {


    styles = {
        paperLabel: {
            marginLeft: 10,
            color: ThemeColors.appBarBackground,
        },
        paper: {
            padding: 0,
            marginBottom: 20
        }
    };

    _calendarRef = React.createRef();
    _currentDatabase = Global.getLastDatabase();
    _luxonValidZones = DateConversions.supportedTimezones();


    // When selected from the calendar ADD button
    _selectedStart = null;
    _selectedEnd = null;
    _selectedResource = null;
    _isAddMonthOrYearView = false;

    _slotClickTime = null;      // the time when the last slot click occurred
    _clickedSlots = [];
    _lastClickedSlot = null;
    _lastClickedResource = null;

    _lastSelectedDate = null;   // the last date selected on the calendar
    _dateSelectTime = null;     // the time when the last date selection occurred

    _slotToCopy = null;         // the last slot that was copied

    // keeps track of the original assigned resources on a Service, prior to modifying the Resource
    _originalAssignedResourcesFromService = [];

    // if the slot being edited is because the user has dragged or changed its time in the BookingCalendar, if the user cancels, we need to know to tell the BookingCalendar to revert
    // to the original.  The Booking calendar has already updated its internal representation.  We will upsert the original back to the BookingCalendar to revert it
    _editingFromDragResize = false;

    _slotPriorToEdit = null;    // the slot before it was edited, so can see if Recurrence information has changed

    constructor(props) {
        super(props);
        this.state.didInitialResourceFetch = false;     // true if we have fetched the resources at least once
        this.state.resources = [];
        this.state.newEditResourcePopoverOpen = false;
        this.state.newSlotSelectionPopoverOpen = false;
        this.state.slotSelectionPopoverOpen = false;
        this.state.editSlotPopoverOpen = false;
        this.state.selectServiceOwnerPopoverOpen = false;
        this.state.selectEventOwnerPopoverOpen = false;
        this.state.editBookingPatronPopoverOpen = false;
        this.state.assignServiceResourcesPopoverOpen = false;
        this.state.recurrenceQuestionOpen = false;
        this.state.calendarOptionsPopoverOpen = false;

        this.state.calendarOptionsPopoverAnchor = null;

        this.state.availableServiceOwners = [];
        this.state.availableEventOwners = [];

        this.state.slotToEdit = null;
        this.state.slotToEditIsNew = false;
        this.state.recurrenceExists = false;

        this.state.isDeletingSlot = false;
        this.state.willRegenerateRecurrence = false;

        this.state.selectedResource = null;

        this.state.createNewResource = true;    // true if we are creating a new resource, false if we are editing an existing one (with the popover)
        this.state.checkedResources = null;     // The resources that are checked (shown on the calendar)
        this.state.timezone = Global.getTimezone();
    }
    
    
    
    /**
     * When the page loads, immediately fetch the resources and timezone for the current database
     */
    componentDidMount() {
        super.componentDidMount();

        window.addEventListener("databaseChangeEvent", this._databaseChanged);
        //Detect control-C and control-V for copy and paste
        window.addEventListener('keydown', this._handleKeyDown);

        this._fetchResources();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("databaseChangeEvent", this._databaseChanged);
        window.removeEventListener('keydown', this._handleKeyDown);
    }        

    _popupIsOpen = () => {
        return this.state.newEditResourcePopoverOpen || this.state.newSlotSelectionPopoverOpen || this.state.slotSelectionPopoverOpen || this.state.editSlotPopoverOpen ||
                this.state.selectServiceOwnerPopoverOpen || this.state.selectEventOwnerPopoverOpen || this.state.assignServiceResourcesPopoverOpen ||
                this.state.calendarOptionsPopoverOpen || this.state.recurrenceQuestionOpen || this.state.editBookingPatronPopoverOpen;
    }

    _handleKeyDown = (event) => {

        // if any popups are open, ignore the rest of the keydown events
        if (this._popupIsOpen())
            return;

        if (event.key === 'Escape') {
            this.setState({selectedResource: null, slotToEdit: null});
            if (this._calendarRef.current)
                this._calendarRef.current.highlightSlot(null);
        }
        else if (event.metaKey && event.key === 'c')
            this._copyLastSelectedSlot();
        else if (event.metaKey && event.key === 'v')
            this._pasteLastSelectedSlot();
        else if (event.key === 'Backspace' || event.key === 'Delete')
            this._deleteLastSelectedSlot();
    }



    // When the database changes, refetch the resources (which will refresh the calendar) and fetch the timezone
    _databaseChanged = () => {
        if (Global.getLastDatabase() !== this._currentDatabase) {
            console.log("Database changed to " + Global.getLastDatabase() + ", refetching resources");
            this.setState({timezone: Global.getTimezone()});        // this will have updated if the database changed
            this._fetchResources();
            this._currentDatabase = Global.getLastDatabase();
        }
    }
   
    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }


    // When the resources are updated, update the calendar with the new resources, and optionally refetch the events.
    _calendarResourcesDidUpdate(resources, andRefetchEvents = false) {
        if (this._calendarRef.current) {
            this._calendarRef.current.updateResources(resources, andRefetchEvents);
        }
    }


    //--------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------- TIMEZONE MANAGEMENT ---------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------------


    _updateTimezone = (timezoneStr) => {
        if (!Global.timezoneNotSet)
            this.showConfirmAlert("Update Timezone", "Are you sure you want to update the timezone for " + Global.getLastDatabase() + " to " + timezoneStr + "?", 
                            'black', "Cancel", this._confirmUpdateTimezone, "Update", 'red');
        else
            this._confirmUpdateTimezone(timezoneStr);
    }

    // Post the new timezone to the server
    _confirmUpdateTimezone = (timezoneStr) => {

        if (!this._luxonValidZones.includes(timezoneStr))
            return;

        this.incrementBusy();
        const timezone = {group: Global.getLastDatabase(), timezone: timezoneStr};
        this.secureJSONFetch("/bk/tz", {method: 'POST', body: JSON.stringify(timezone)}, (response) => this._updateTimezoneCallback(response, timezoneStr), this._fetchErrorCallback);
    }

    // When the timezone is updated, update the timezone state and show a success alert, this will also refresh the calendar
    _updateTimezoneCallback = (response, timezoneStr) => {
        this.decrementBusy();
        this.setState({timezone: timezoneStr}); 
        this.showConfirmAlert("Success", "Timezone for " + Global.getLastDatabase() + " updated to " + timezoneStr, 'green');
        Global.setTimezone(timezoneStr);
        Global.timezoneNotSet = false;
        
        if (this._calendarRef.current)
            this._calendarRef.current.updateTimezone(timezoneStr);
    }


    //--------------------------------------------------------------------------------------------------------------------------
    //------------------------------------------ SUBSCRIPTION MANAGEMENT -------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------------

    _getIcalSubscription = () => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/ical/" + Global.getLastDatabase(), {}, this._getIcalSubscriptionCallback, this._fetchErrorCallback);
    }

    _getIcalSubscriptionCallback = (response) => {
        this.decrementBusy();
        if (response) {
            const link = "https://booking.accessgrantedsystems.net/ical/" + response.subscriptionKey;
            const msg = <span>
                            Copy the following URL to your calendar application to subscribe to your internal Calendar (all Events and Bookings). Only share this link with
                            people who are authorized to view all community Events (including private Events) and all patron Bookings (usually your staff).<br/><br/>
                            <span style={{color: 'blue'}}>{link}</span>
                        </span>;
            this.showConfirmAlert("iCal Internal Subscription", msg, 'black', "Ok", this._rekeyICalSubscription, "Re-key", 'red');
        }
    }

    _rekeyICalSubscription = () => {
        this.showConfirmAlert("Regenerate Internal Subscription Link", "Are you sure you want to regenerate the Subscription Link? Any subscribers will need to re-subscribe with the new link.",
            'black',
            "Cancel", 
            this._confirmRekey,
            "Re-key",
            'red');
    }

    _getPublicIcalSubscription = () => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/ical/" + Global.getLastDatabase() + "/public", {}, this._getIcalPublicSubscriptionCallback, this._fetchErrorCallback);
    }


    _confirmRekey = () => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/ical/" + Global.getLastDatabase(), {method: 'POST'}, this._getIcalSubscriptionCallback, this._fetchErrorCallback);
    }


    _getIcalPublicSubscriptionCallback = (response) => {
        this.decrementBusy();
        if (response) {
            const link = "https://booking.accessgrantedsystems.net/ical/" + Global.getLastDatabase() + "/" + response.subscriptionKey;
            const msg = <span>
                            Copy the following URL to your calendar application to subscribe to the community's Public Calendar (all public Events). You may share this link with
                            your patrons to allow them to subscribe for public Events.<br/><br/>
                            <span style={{color: 'blue'}}>{link}</span>
                        </span>;
            this.showConfirmAlert("iCal Public Subscription", msg, 'black');
        }
    }

    _getPatronBookingLink = () => {
        const link = "https://booking.accessgrantedsystems.net/patron/booking?database=" + Global.getLastDatabase();
        const msg = <span>
                        The following URL can be shared with your Patrons to allow them to book your Services:<br/><br/>
                        <span style={{color: 'blue'}}>{link}</span>
                    </span>;
        this.showConfirmAlert("Patron Booking", msg, 'black');
    }

    _getEventSignupLink = () => {   
        const link = "https://booking.accessgrantedsystems.net/patron/event?database=" + Global.getLastDatabase();
        const msg = <span>
                        The following URL can be shared with your Patrons to allow them to sign up for your public Events:<br/><br/>
                        <span style={{color: 'blue'}}>{link}</span>
                    </span>;
        this.showConfirmAlert("Patron Event Signup", msg, 'black');
    }

    

    //--------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------- RESOURCE MANAGEMENT ---------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------------

    // Fetch the resources for the current database
    _fetchResources = () => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/" + Global.getLastDatabase() + "/resources", {}, this._fetchResourcesCallback, this._fetchErrorCallback); 
    }

    // When the resources are fetched, update the list of resources. If this is the first time, check all the resources,
    // otherwise, only check the resources that were previously checked and are still in the list.
    // Then, refresh the calendar
    _fetchResourcesCallback = (response) => {

        if (response) {
            const resources = response.map((json) => Constructors.resourcefromJson(json, this.state.timezone));

            let checkedResources;
            if (!this.state.checkedResources)   // If we haven't checked any resources yet, check all of them
                checkedResources = resources.map((resource) => resource);
            else { 
                
                // remove resources that are no longer in the list, remove them
                checkedResources = this.state.checkedResources.filter((checkedResource) => resources.find((resource) => checkedResource.id === resource.id));

                // if there are new resources that aren't in the checked list, add them
                const newResources = resources.filter((resource) => !this.state.checkedResources.find((checkedResource) => checkedResource.id === resource.id));
                checkedResources = checkedResources.concat(newResources);

                // make sure old checked resources objects are synced with incoming resource objects
                checkedResources = checkedResources.map((checkedResource) => resources.find((resource) => checkedResource.id === resource.id));
                
            }
            console.log("Fetched " + resources.length + " resources, " + checkedResources.length + " checked");

            this._calendarResourcesDidUpdate(checkedResources, true);
            
            this.setState({resources: resources, checkedResources: checkedResources, selectedResource: null, didInitialResourceFetch: true});
            this.decrementBusy();
        }
    }

    // Create a new resource or service or edit an existing one, post it to the server, and refetch the resources
    _createEditNewResourceCallback = (type, name, category, description) => {
        this.setState({newEditResourcePopoverOpen: false});
        this.incrementBusy();

        let resource;
        if (this.state.createNewResource) {

            const nextColor = Resource.getNextColor(this.state.resources.length);

            switch (type) {
                case "Resource":
                    resource = Resource.requestNew(name, category, description, nextColor);
                    break;
                case "Service":
                    resource = Service.requestNew(name, category, description, nextColor);
                    break;
                default:
                    console.error("Unknown class: " + type);
                    return;
            }

            const json = resource.toJsonForPost();

            this.secureJSONFetch("/bk/resources", {method: 'POST', body: json},
                                 () => { this.decrementBusy(); this._fetchResources();}, this._fetchErrorCallback);
        }
        else {
            resource = this.state.selectedResource;
            resource.name = name;
            resource.category = category;
            resource.description = description;

            const json = resource.toJsonForPost();
            
            // If success, we will be in sync with the server, so we don't need to refetch
            this.secureJSONFetch("/bk/resources", {method: 'POST', body: json}, 
                                (response) => this._updateResourceCallback(resource), this._updateResourceFailed);
        }

       
    }


    _selectResource = (resource) => {
        this.setState({selectedResource: null});   
        if (this.state.selectedResource && this.state.selectedResource.id === resource.id)
            return;
        
        setTimeout(() => this.setState({selectedResource: resource}));
    }


    // Update the selected resource, and refetch events if requested. When changing some attributes of Resource, we don't need to refetch, because Slots are not affected.
    // But other attributes, such as the assignableResourceIds of a Service, require a refetch because this may change the Slots assigned Resources
    _updateResource = (resource = null, andRefetchEvents = false) => {
        if (!resource)
            resource = this.state.selectedResource;
        this.incrementBusy();

        const json = resource.toJsonForPost();

        this.secureJSONFetch("/bk/resources", {method: 'POST', body: json}, 
                             (response) => this._updateResourceCallback(resource, andRefetchEvents), this._updateResourceFailed);
    }


    // When the resource is updated, we may need to refetch
    _updateResourceCallback = (resource, refetch) => {
        this.decrementBusy();
        resource.updateCount++;      // we need to update our count because the server did, and we need to stay in sync
        
         // we need to update all the resources, this one, and others because the category may have changed. We may need to refetch events
         this._calendarResourcesDidUpdate(this.state.checkedResources, refetch);
    }

    // If the resource update failed, our state is now out of sync with the server, so we need to refetch the resources
    _updateResourceFailed = (error) => {
        this._fetchErrorCallback(error);
        this.setState({selectedResource: null});
        this._fetchResources();
    }

    _deleteResource = () => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/resources/futureSlotCounts?resourceIDs=" + this.state.selectedResource.id, {}, this._deleteResourceGetCountCallback, this._fetchErrorCallback);
    }

    _deleteResourceGetCountCallback = (response) => {
        this.decrementBusy();
        
        if (!response) {
            console.error("No response from server");
            return;
        }

        let notice = "<span>Are you sure you want to delete \"" + this.state.selectedResource.name + "\" ? ";
        if (response.futureEventsAffected === 0 && response.futureBookingsAffected === 0 && response.futureEventsDeleted === 0)
            notice += "There is nothing scheduled on this " + this.state.selectedResource.type + " in the future that will be affected.</span>";
        else {

            let types = [];
            if (response.futureBookingsAffected > 0)
                types.push("Bookings");
            if (response.futureEventsAffected > 0 || response.futureEventsDeleted > 0)
                types.push("Events");

            notice += "Either future or in-progress " + types.join(" and ") + " will be affected by deleting this " + this.state.selectedResource.type + ". The following will occur:<br/>";
            notice += "<ul>";
            switch (this.state.selectedResource.type) {
                case "Resource":
                    if (response.futureEventsAffected > 0)
                        notice += "<li>" + response.futureEventsAffected + " Events will have this Resource <span style='font-weight: bold'>removed</span></li>";
                    if (response.futureEventsDeleted > 0)   
                        notice += "<li>" + response.futureEventsDeleted + " Events only use this Resource and will be <span style='color: red; font-weight: bold'>deleted</span></li>";
                    if (response.futureBookingsAffected > 0)
                        notice += "<li>" + response.futureBookingsAffected + " Bookings will have this Resource <span style='font-weight: bold'>unassigned</span>. Patrons are not notified.</li>";
                    break;
                case "Service":
                    if (response.futureBookingsAffected > 0)
                        notice += "<li>" + response.futureBookingsAffected + " Bookings on this Service will be <span style='color: red; font-weight: bold'>cancelled</span></li>";
                    break;
                default:
                    console.error("Unknown resource type: " + this.state.selectedResource.type);
                    return;
            }
            notice += "</ul></span>";
        }

        const msg = <div dangerouslySetInnerHTML={{ __html: notice }} />

        this.showConfirmAlert("Delete " + this.state.selectedResource.type, 
            null, 'black',
            "Cancel", 
            this._deleteResourceConfirm,
            "Delete",
            'red',
            msg);
    }

    _deleteResourceConfirm = () => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/resources/" + this.state.selectedResource.id, {method: 'DELETE'}, this._deleteComplete, this._fetchErrorCallback);
    }

    _deleteComplete = () => {
        this.decrementBusy();
        this.setState({selectedResource: null});
        this._fetchResources();
    }


    // When a resource is checked or unchecked, add or remove it from the list of checked resources, and refresh the calendar
    // We need to refetch events because the resources may be shown or not shown, which needs to hide or show the events
    _resourceCheckChanged = (resource, checked) => {
        let checkedResources = [...this.state.checkedResources];
        if (checked)
            checkedResources.push(resource);
        else
            checkedResources = checkedResources.filter((r) => r.id !== resource.id);
        
        this.setState({checkedResources: checkedResources});  
        this._calendarResourcesDidUpdate(checkedResources, true);  
    }

    // Create a button for a resource, with a checkbox to show it on the calendar, and a button to select the resource as the default for new slots, and for editing
    _resourceButton = (resource, index) => {
        const isSelected = this.state.selectedResource && this.state.selectedResource.id === resource.id;    
        const isChecked = this.state.checkedResources && this.state.checkedResources.find((r) => r.id === resource.id) ? true : false;

        const tooltip = <span>
                            <span style={{fontWeight: 'bold', fontSize: 12}}>{resource.name}</span>
                            <br/>
                            {resource.description}
                            <br/><br/>
                            {resource.type === "Service" ? 
                                <Fragment>{"Owner: " + (resource.ownerName ? resource.ownerName : "None")}<br/><br/></Fragment>
                                : null
                            }
                            Click to select or drag this {resource.type} to a new category
                        </span>

        return (<div key={index} style={{display: 'flex', alignItems: 'center', marginTop: -5, marginBottom: -5}}>
                    <Tooltip title={isChecked ? "Hide from Calendar" : "Show on Calendar"}>
                        <Checkbox color='primary' checked={isChecked} onChange={(event) => {this._resourceCheckChanged(resource, event.target.checked)}}/>
                    </Tooltip>
                    <Tooltip title={tooltip}>
                        <div style={{textDecoration: (isSelected ? 'underline' : ''), cursor: 'pointer'}} onClick={() => this._selectResource(resource)}>{resource.name}</div>
                    </Tooltip>
               </div>); 
    }


    // Get the list of available users to select as the new service owner
    _selectServiceOwner = () => {
        // fetch available users
        this.incrementBusy();
        this.secureJSONFetch("/bk/" + Global.getLastDatabase() + "/services/users", {}, this._fetchServiceUsersCallback, this._fetchErrorCallback); 
    }

    _fetchServiceUsersCallback = (response) => {
        this.decrementBusy();
        //response is an array of user objects, with name and id fields
        this.setState({availableServiceOwners: response, selectServiceOwnerPopoverOpen: true});
    }

    // Callback from the selectServiceOwnerPopover when the user selects a new service owner, update the service owner - "owner" is the same object as returned from fetchServiceUsersCallback
    _updateServiceOwner = (owner) => {
        const service = this.state.selectedResource;
        service.owner = owner ? owner.id : null;
        service.ownerName = owner ? owner.name : null;
        this.setState({selectedResource: service, selectServiceOwnerPopoverOpen: false});
        this._updateResource();
    }


    // If all resources of the specified type are not checked, check them, otherwise uncheck them all, refresh the calendar resources and pull the events
    // we need to refetch events because the resources may be shown or not shown, which needs to hide or show the events
    _selectAllResources = (type) => {
        let checkedResources = this.state.checkedResources.filter((r) => r.type === type);
        let allResources = this.state.resources.filter((r) => r.type === type);

        if (checkedResources.length < allResources.length) {    // select all Resources
            const allChecked = [...this.state.checkedResources];    // new array to force a re-render
            for (let resource of allResources) {
                if (!allChecked.find((r) => r.id === resource.id))      // if not already checked, add it
                    allChecked.push(resource);
            }
            this.setState({checkedResources: allChecked});
            this._calendarResourcesDidUpdate(allChecked, true);

        }
        else {  // deselect all Resources
            checkedResources = this.state.checkedResources.filter((r) => r.type !== type);  // only keep the types that are not the type we are unchecking
            this.setState({checkedResources: checkedResources});
            this._calendarResourcesDidUpdate(checkedResources, true);

        }
    }

   
    _selectAssignableResourcesForService = () => {
        this._originalAssignedResourcesFromService = [...this.state.selectedResource.assignableResourceIds];  // copy this list, so we can compare later
        this.setState({assignServiceResourcesPopoverOpen: true});
    }

    // When the user selects the resources to assign to the service, close the popover and check if any resources were removed, to warn the user
    _serviceAssignedResources = () => {

        this.setState({assignServiceResourcesPopoverOpen: false});

        // Get an array of removed resources
        const removedResources = this._originalAssignedResourcesFromService.filter((r) => !this.state.selectedResource.assignableResourceIds.includes(r));

        if (removedResources.length > 0) {
            // Get a comma separated string of resource IDs, from the removedResources array
            const resourceIDs = removedResources.join(",");

            this.secureJSONFetch("/bk/resources/futureSlotCounts?resourceIDs=" + resourceIDs, {}, this._serviceAssignedResourcesCallback, this._fetchErrorCallback);
            return;
        }
        
        // unchanged, just update the service
        this._serviceAssignedResourcesCallback();
    }   

    _serviceAssignedResourcesCallback = (response) => {

        if (response) {
            let notice = "";
            if (response.futureBookingsAffected > 0) {
                notice += "You are removing " + response.resourceIDs.length + " assigned Resources from the Service: \"" + this.state.selectedResource.name + "\". There are " + 
                        response.futureBookingsAffected + " future Bookings assigned these Resources that will no longer have the Resources assigned. Patrons are not notified.";

                this.showConfirmAlert("Update Service Assigned Resources", 
                    notice, 'black',
                    "Cancel", 
                    this._confirmServiceAssignedResources,
                    "Proceed",
                    'green',
                    null,
                    this._cancelServiceAssignedResources
                );

                return;
            }
        }
        this._confirmServiceAssignedResources();
    }

    _cancelServiceAssignedResources = () => {
        this.state.selectedResource.assignableResourceIds = this._originalAssignedResourcesFromService;  // revert the changes
        this.setState({selectedResource: this.state.selectedResource}); // force a re-render
    }

    _confirmServiceAssignedResources = () => {
        this._updateResource(null, true);
    }

    // This is going to be done by the server. We need to reset the colors of the slots for the resource to match the resource's default color.
    // We will need to refetch all the slots again too
    _resetSlotColorsForResource = () => {

        const slotName = this.state.selectedResource instanceof Service ? "Bookings" : "Events";

        const eventException = this.state.selectedResource.type === "Resource" ? " Events scheduled on multiple Resources will not be affected." : "";

        this.showConfirmAlert("Confirm Reset Color", 
                               "All " + slotName + " scheduled on \"" + this.state.selectedResource.name + "\" will have their calendar colors set to the default " +
                               "color for this " + this.state.selectedResource.type + "." + eventException + " Continue?", 'black',
                                "Cancel", 
                                this._confirmResetColors,
                                "Proceed",
                                'green');     
    }

    _confirmResetColors = () => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/resources/" + this.state.selectedResource.id + "/resetColor", {method: 'POST'}, 
                             this._confirmResetColorsCallback, this._fetchErrorCallback);
    }

    _confirmResetColorsCallback = (response) => {
        this.decrementBusy();
        if (this._calendarRef.current)
            this._calendarRef.current.refetchEvents()
        
        const slotName = this.state.selectedResource instanceof Service ? "Bookings" : "Events";

        this.showConfirmAlert("Success", "Colors updated on " + response + " " + slotName, 'green');
    }


    _resourceDragEnd = (result) => {
        const { destination, source } = result;
        
        if (!destination) //dropping over nothing
            return;
   
        //dropping over itself
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;
       
        const dropCategory = destination.droppableId.substring("CATEGORY".length);  // remove the "CATEGORY" prefix
        const draggableResource = this.state.resources.find((r) => r.name === result.draggableId);

        if (!draggableResource)
            return;     // this shouldn't happen

        // If the resource is being moved to a different category, update the resource's category
        if (draggableResource.category !== dropCategory) {
            draggableResource.category = dropCategory;
            this._updateResource(draggableResource, false);

             // we need to update all the resources, because the category has changed
            this._calendarResourcesDidUpdate(this.state.checkedResources, false);
            return;
        }

    }


    //--------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------- SLOT MANAGEMENT -------------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------------


    // cancel requesting to add a new slot, clear the selected start and end times
    _cancelAddSlot = () => {
        this.setState({newSlotSelectionPopoverOpen: false});
        this._selectedStart = null;
        this._selectedEnd = null;
    }

    // Add a slot for the selected resource, of the selected type, from the selected start to end (if they are set)
    // the slot type is one of 'Event', 'Block', 'Booking'
    // show the EditSlot popover with the new slot
    _addSlot = (resource, slotType) => {

        // No start time? use default start date as the current date and time, to the next hour, zeroing the reminder of the hour
        if (!this._selectedStart) {
            this._selectedStart = DateConversions.now(this.state.timezone).plus({hour: 1});
            this._selectedStart = this._selectedStart.set({minute: 0, second: 0, millisecond: 0});
        }
        else {
            // when the user clicks add when they're in month or year view, they're not picking a time, they're picking a date.  We'll choose noon.
            // in week or day view, we assume they are picking the start time.
            if (this._isAddMonthOrYearView) {
                this._selectedStart = this._selectedStart.set({hour: 12, minute: 0, second: 0});
                // if start->stop spans more than a day, set the end to that day, and use the same hour as start
                if (this._selectedEnd && this._selectedEnd.diff(this._selectedStart, 'days').days > 1)
                    this._selectedEnd = this._selectedEnd.set({hour: 12});
                else
                    this._selectedEnd = null;   // below will set the end to 1 hour after the start
            }
        }

        // No end time? use the start time plus 1 hour
        if (!this._selectedEnd)
            this._selectedEnd = this._selectedStart.plus({hour: 1});

        let newSlot;
        switch (slotType) {
            case "Availability":
                newSlot = Availability.createNew(this._selectedStart, this._selectedEnd, resource);
                break;
            case "Block":
                newSlot = Block.createNew(this._selectedStart, this._selectedEnd, resource);
                break;
            case "Event":
                newSlot = CalEvent.createNew(this._selectedStart, this._selectedEnd, resource);
                break;
            case "Booking":
                newSlot = Booking.createNew(this._selectedStart, this._selectedEnd, resource);
                break;
            default:
                console.error("Unknown slot type");
                return;
        }
        console.log("Adding slot for " + resource.name + " of type " + slotType + " from " + this._selectedStart.toISO() + " to " + this._selectedEnd.toISO());

        // Validate that the user has permission to add the slot with the selected resource
        if (!newSlot.canEdit([resource])) {
            this.showConfirmAlert("Error", "You do not have permission to add a " + slotType + " to " + resource.name, 'red');
            return;
        }

        this.setState({newSlotSelectionPopoverOpen: false});
        this._editSlot(null, newSlot);  // edit the new slot
    }


    // function call to edit a slot. Pass the original slot being edited, and the changingSlot slot (which may have changes, for instance if it was dragged to a new time)
    // originalSlot can be null if it is a new slot. Note these should not point to the same object.
    // Also pass a boolean indicating if the slot was changed in the BookingCalendar, so we can revert if the user cancels
    _editSlot = (originalSlot, changingSlot, didChangeInBookingCalendar = false) => {

        this._editingFromDragResize = didChangeInBookingCalendar;
        const isNew = originalSlot ? false : true;

        // store the slot before it was edited, so we can see if times or recurrence information has changed, and recover if cancelled
        this._slotPriorToEdit = originalSlot;

        if (originalSlot && originalSlot instanceof Booking && originalSlot.inBookingByPatron()) {
            this.showConfirmAlert("Error", "The patron is currently in the confirmation or payment flow process for this Booking. It cannot be changed now.", 'red');
            this._cancelEditSlot();
            return;
        }
       
        if (changingSlot.recurrenceId) {        // fetch the recurrence if it is a recurring slot, then call _showEditSlot
            this.incrementBusy();
            this.secureJSONFetch("/bk/recurrences/" + changingSlot.recurrenceId, {}, 
                                 (response) => {this.decrementBusy();
                                                this._showEditSlot(changingSlot, isNew, response)
                                               }, 
                                 (error) => {this.decrementBusy(); 
                                             this._showEditSlot(changingSlot, isNew); 
                                             this._fetchErrorCallback(error); });
        }
        else {
            this._showEditSlot(changingSlot, isNew);        // just call _showEditSlot
        }
    }

    _showEditSlot = (slot, isNew, recurrenceResponse = null) => {

        const recurrence = recurrenceResponse ? new Recurrence(recurrenceResponse) : null;

        if (this._slotPriorToEdit)
            this._slotPriorToEdit.recurrenceObj = recurrence ? recurrence.copy() : null;    // store the recurrence before it was edited, temporarily in the slot

        const recurrenceExists = recurrence ? true : false;  // you can only add a recurrence if it doesn't already exist

        console.log("Editing slot: " + slot.id + " isNew: " + isNew + " recurrenceExists: " + recurrenceExists);

        slot.recurrenceObj = recurrence;    // store the recurrence to potentially edit in the slot to edit, temporarily
        this.setState({slotToEdit: slot, slotToEditIsNew: isNew, editSlotPopoverOpen: true, recurrenceExists: recurrenceExists});
    }


    // callback from EditSlot when the user clicks "Apply Changes"
    _slotModified = () => {
        const slot = this.state.slotToEdit;

        let recurrenceDidChange = false;
        // See if there were any changes
        if (this._slotPriorToEdit) {

            // If neither have a recurrence, or both have a recurrence, and the recurrences are the same, then no changes
            if ( (!slot.recurrenceObj && !this._slotPriorToEdit.recurrenceObj) ||
                  (slot.recurrenceObj && this._slotPriorToEdit.recurrenceObj && !slot.recurrenceObj.recurrenceChanged(this._slotPriorToEdit.recurrenceObj)) )
                recurrenceDidChange = false;
            else
                recurrenceDidChange = true;

            // Slot is identical, so check recurrence. Slot json can be identical in json even if the recurrence is different, since the recurrence obj id is the same
            if (slot.toJsonForPost() === this._slotPriorToEdit.toJsonForPost()) {

                // If neither have a recurrence, or both have a recurrence, and the recurrences are the same, then no changes
                if (!recurrenceDidChange) {
                    this.showConfirmAlert("No Changes", "No changes were made to the " + slot.type, 'black');
                    this._cancelEditSlot();
                    return;
                }
            }
        }


        if (slot instanceof Block) {
            // get the number of resources that are services, and see if there are more than one unique owner
            let serviceOwner = null;
            let multipleOwners = false;
            let hasResources = false;
            for (let resourceId of slot.resourceIds) {
                const resource = this.state.resources.find(r => r.id === resourceId);
                if (!resource) {
                    console.error("Block " + slot.id + " has a resource ID that is not in the resources list: " + resourceId);
                    continue;
                }
                if (resource instanceof Service) {
                    if (serviceOwner === null)
                        serviceOwner = resource.owner;
                    else if (serviceOwner !== resource.owner) {
                        multipleOwners = true;
                        break;
                    }
                }
                else
                    hasResources = true;
            }

            if (multipleOwners) {
                this.showConfirmAlert("Warning", 
                                "This Block is on multiple Services with different owners, which is not typical. It will not be editable by those Service owners if they do not also have MANAGE RESOURCE permissions. Continue?", 'black',
                                "Cancel", 
                                this._confirmSlotModify,
                                "Proceed",
                                'red');
            }
            else if (serviceOwner && hasResources) {
                this.showConfirmAlert("Warning", 
                                "This Block is on a Service and at least one Resource, which is not typical. It will not be editable by that Service's owner if they do not also have MANAGE RESOURCE permissions. Continue?", 'black',
                                "Cancel", 
                                this._confirmSlotModify,
                                "Proceed",
                                'red'); 
            }
            else
                this._confirmSlotModify();

        }
        else if (slot.state === "PENDING_CANCEL" || slot.state === "CANCELLED") {
            if (!this._slotPriorToEdit.identicalTimeTo(slot) || recurrenceDidChange) {    // times have changed (this._slotPriorToEdit must exist since state is in a cancelled state)
                this.showConfirmAlert("Warning", 
                    "Changing time or recurrence on an already cancelled " + slot.type + ". Continue?", 'black',
                    "Cancel", 
                    this._confirmSlotModify,
                    "Proceed",
                    'red'); 
            }
            else
                this._confirmSlotModify();
        }
        else
            this._confirmSlotModify();
    }


    _confirmSlotModify = () => {

        // Check how many slots are ACTIVE in the future, and if there are any we need to know about it
        if (this._slotPriorToEdit && this._slotPriorToEdit.recurrenceObj) {
            const fromQuery = "?fromDate=" + DateConversions.luxonDateTimeToJsonDateString(this._slotPriorToEdit.start);

            this.secureJSONFetch("/bk/recurrences/" + this._slotPriorToEdit.recurrenceObj.id + "/countActive" + fromQuery, {},
                                 (response) => this._continueConfirmSlotModify(response.count),
                                  this._fetchErrorCallback);
        }
        else {
            this._continueConfirmSlotModify();
        }
    }

    // User confirmed the slot modification, check on Recurrence and pose the Recurrence Question to the user if needed.
    _continueConfirmSlotModify = (activeCount = 0) => {
        console.log(activeCount + " active slots in the future");
        const slotEditing = this.state.slotToEdit;

        // If we have an existing recurrence, we need to know how the slot should change.
        let triggerRecurrenceRefresh = false;
        if (this._slotPriorToEdit && this._slotPriorToEdit.recurrenceObj) {

            if (!this._slotPriorToEdit.identicalTimeTo(slotEditing))     // times have changed, so we need to recreate all future slots in the recurrence series
                triggerRecurrenceRefresh = true;

            if (this._slotPriorToEdit.recurrenceObj.recurrenceChanged(slotEditing.recurrenceObj)) // recurrence pattern has changed, so we need to recreate all future slots in the recurrence series, and post the new recurrence object
                triggerRecurrenceRefresh = true;

            // show the popup and let the user take action
            this.setState({recurrenceQuestionOpen: true, willRegenerateRecurrence: triggerRecurrenceRefresh, isDeletingSlot: false, recurringActiveCount: activeCount});
        }
        // If this is a new slot with recurrence, or we are adding a new recurrence to an existing slot that does not have one then:
        // 1. For existing slots: delete the existing slot. 
        // 2. Post the recurrence - which will regenerate this slot (or create it if new), and recurring ones
        else if (slotEditing.recurrenceObj) { 
            if (!this.state.slotToEditIsNew) {

                if (slotEditing.state !== "CREATED") {
                    this.showConfirmAlert("Error", "You cannot add recurrence to an existing " + slotEditing.type + " unless it is in the state \"CREATED\".", 'red');
                    return;
                }

                this.incrementBusy();
                this.secureJSONFetch("/bk/slots/" + slotEditing.id, {method: 'DELETE'}, 
                                    (response) => {this.decrementBusy(); this._postRecurrence()},
                                    this._fetchErrorCallback);
            }
            else {
                this._postRecurrence();
            }
        }
        else
            this._postSlotModify();     // no recurrence, just post the slot

    }

    // Callback when the user selects "Just This One" from the Recurrence question, we only need to modify the slot being edited or deleted
    _slotRecurrenceJustThisOne = () => {
        this.setState({recurrenceQuestionOpen: false, editSlotPopoverOpen: false});

        if (this.state.isDeletingSlot)
            this._confirmDeleteSlot();      // just delete this slot
        else {
            this.state.slotToEdit.recurrenceId = null;      // remove the recurrence id, take this slot out of the recurrence series
            this._postSlotModify();
        }
    }

    // Callback when the user selects "This and Future" from the Recurrence question. When deleting a slot, we need to delete all slots in the future 
    // When modifying a slot, we need to regenerate the slots in the future, if "willRegenerateRecurrence" is true
    _slotRecurrenceThisAndFuture = () => {
        this.setState({recurrenceQuestionOpen: false, editSlotPopoverOpen: false});

        if (!this._slotPriorToEdit) {   // should never happen
            console.error("No slot prior to edit, cannot modify this and future");
            return;
        }

        const slot = this.state.slotToEdit;

        if (this.state.isDeletingSlot)
            this._deleteSlotSeries(slot.recurrenceId, slot.start, true);      // delete all slots in the series on and after this one
        else {
            // If we need to regenerate, we will first delete this and all future slots, then we will create a new recurrence object
            // on the server, post it, and then generate the new recurring slots
            if (this.state.willRegenerateRecurrence) {
                this._deleteSlotSeries(this._slotPriorToEdit.recurrenceId, this._slotPriorToEdit.start, false);   // no need to refresh, we will do that after we post the new slots

                slot.recurrenceObj.changeId();   // we are going to create a new series, so change the id
                this._postRecurrence();         // post the new recurrence object, which will generate the new slots
            }
            // If we are not regenerating, we just need to modify this slot and all future slots, set the fromDate to the start time of this slot
            else {
                this.incrementBusy();
                const toPost = slot.toJsonForPost();
                this.secureJSONFetch("/bk/slots/series?all=false", {method: 'PUT', body: toPost}, this._postSlotSeriesCallback, this._modifySlotSeriesError);
            }
        }
    }

    // Callback when the user selects "All" from the Recurrence question
    _slotRecurrenceAll = () => {
        const slot = this.state.slotToEdit;

        this.setState({recurrenceQuestionOpen: false, editSlotPopoverOpen: false});

        if (this.state.isDeletingSlot)  // delete the entire series
            this._deleteSlotSeries(this.state.slotToEdit.recurrenceId, null, true);       // delete all slots in the series
        else if (this.state.willRegenerateRecurrence)
            console.error("Recurrence All not available when regenerating a recurrence");
        else {
            // we will modify all slots in the series
            this.incrementBusy();
            this.secureJSONFetch("/bk/slots/series?all=true", {method: 'PUT', body: slot.toJsonForPost()}, this._postSlotSeriesCallback, this._modifySlotSeriesError);
        }
    }

    _postSlotModify = () => {
        this.incrementBusy();
        this.secureJSONFetch("/bk/slots", {method: 'POST', body: this.state.slotToEdit.toJsonForPost()}, this._slotModifiedCallback, this._fetchErrorCallback);
    }

    _postRecurrence = () => {
        this.setState({editSlotPopoverOpen: false});

        const slot = this.state.slotToEdit;
        this.incrementBusy();
        this.secureJSONFetch("/bk/recurrences", {method: 'POST', body: slot.recurrenceObj.toJsonForPost()}, 
                            this._postSeries, this._fetchErrorCallback);
    }

    // New recurrence object was posted (in the slot being edited), so now generate the new recurring slots
    _postSeries = () => {
        this.decrementBusy();

        // All recurring slots are based on the slot being edited and its recurrence object
        const slot = this.state.slotToEdit;
        const slots = slot.recurrenceObj.generateSlots(slot).map(slot => slot.toJsonForPost(false));     // generate and convert but don't stringify the objects

        this.incrementBusy();
        this.secureJSONFetch("/bk/slots/series", {method: 'POST', body: JSON.stringify(slots)}, this._postSlotSeriesCallback, this._fetchErrorCallback);
    }

    _postSlotSeriesCallback = (response) => {
        this.decrementBusy();
        this.setState({editSlotPopoverOpen: false, slotToEdit: null});
        this._slotPriorToEdit = null;
        
        if (this._calendarRef.current)
            this._calendarRef.current.refetchEvents();      // many new slots, so we need to refetch all events
    }

    // Error modifying a series. Some may have been modified, so we need to refresh the calendar
    _modifySlotSeriesError = (error) => {
        this._fetchErrorCallback(error);
        if (this._calendarRef.current)
            this._calendarRef.current.refetchEvents();
    }


    _slotModifiedCallback = (response) => {
        this.decrementBusy();
        let didChange = false;
        if (response) {
            const slot = Constructors.slotFromJson(response, this.state.timezone);
            if (slot) {
                if (this._calendarRef.current) {
                    this._calendarRef.current.upsertSlot(slot);
                    didChange = true;
                }
                if (slot instanceof Booking && slot.state === "ACTIVE" && slot.patron && slot.patron.patronEmail) {
                    this.showConfirmAlert("Success", "Booking for \"" + slot.patron.patronNames[0] + "\" has been updated. If the Booking times, Service, or Resources changed, or the patron email has changed, the patron will receive an email notification.", 'green');
                }
                else if (slot instanceof CalEvent && slot.state === "ACTIVE" && slot.totalPatronCount() > 0) {
                    this.showConfirmAlert("Success", "Event has been updated. If the Time or Resources changed, all the patrons who are signed up will receive an email notification.", 'green');
                }
            }
        }

        this.setState({editSlotPopoverOpen: false, slotToEdit: null});
        this._slotPriorToEdit = null;
        return didChange;
    }


    // callback from EditSlot when the user clicks "Delete", show a confirm alert
    _deleteSlot = () => {
        const slot = this.state.slotToEdit;
    
        if (slot.recurrenceId) {
            // show the popup and let the user take action
            this.setState({recurrenceQuestionOpen: true, willRegenerateRecurrence: false, isDeletingSlot: true});
        }
        else {

            deleteSlotConfirmation(slot, false, this.showConfirmAlert, this._confirmDeleteSlot)

        }
    }

    // callback from the confirm alert when the user confirms the delete, try and delete it from the server
    _confirmDeleteSlot = () => {
        const slot = this.state.slotToEdit;
        this.incrementBusy();
        this.secureJSONFetch("/bk/slots/" + slot.id, {method: 'DELETE'}, this._slotDeletedCallback, this._fetchErrorCallback);
    }

    // callback from the server when the slot is deleted, remove it from the calendar
    _slotDeletedCallback = (response) => {
        this.decrementBusy();
        const slot = this.state.slotToEdit;
        this.setState({editSlotPopoverOpen: false, slotToEdit: null});
        this._slotPriorToEdit = null;

        // If this slot was cancelled, just update its state, don't remove it
        if (this._calendarRef.current)
            if (slot.state === "ACTIVE") {
                slot.state = "PENDING_CANCEL";
                this._calendarRef.current.upsertSlot(slot);
            }
            else
                this._calendarRef.current.removeSlot(slot.id); 
    }

    // Delete slots with the recurrenceId, starting from the fromDate, if it is null, delete all slots in the series
    _deleteSlotSeries = (recurrenceId, fromDate, andRefresh) => {

        const fromQuery = (fromDate ? "&fromDate=" + DateConversions.luxonDateTimeToJsonDateString(fromDate) : "");

        this.incrementBusy();
        this.secureJSONFetch("/bk/slots/series?recurrenceId=" + recurrenceId + fromQuery, {method: 'DELETE'}, andRefresh ? this._deleteSlotSeriesCallback : null, this._fetchErrorCallback);
    }

    _deleteSlotSeriesCallback = () => {
        if (this._calendarRef.current)
            this._calendarRef.current.refetchEvents();      // many slots deleted, so we need to refetch all events
    }

    _cancelEditSlot = () => {
        this.setState({editSlotPopoverOpen: false, slotToEdit: null});
        if (this._editingFromDragResize && this._calendarRef.current) {       // revert change, if there was an original
            if (this._slotPriorToEdit) {
                this._slotPriorToEdit.recurrenceObj = null;                 // calendar doesn't need this
                this._calendarRef.current.upsertSlot(this._slotPriorToEdit);
            }
        }

        this._slotPriorToEdit = null;
    }

    _bookingPatronEdit = () => {
        this.setState({editBookingPatronPopoverOpen: true});
    }

    _manualBookingConfirm = () => {
        const booking = this.state.slotToEdit;

        if (!booking.patron.patronNames || booking.patron.patronNames.length === 0) {
            this.showConfirmAlert("Error", "Add at least one Patron by pressing \"Edit Patrons\"", 'red');
            return;
        }

        const service = booking.getService(this.state.resources);   // get the service object
        const preferredTitle = service.name + " - " + booking.patron.patronNames[0];

        let titleMessage = "";
        let title = booking.title;
        if (booking.title === "Untitled Booking") {
            titleMessage = " The booking title \"Untitled Booking\" will be changed to \"" + preferredTitle + "\".";
            title = preferredTitle;
        }

        this.showConfirmAlert("Confirm Booking", 
            "Are you sure you want to confirm the booking for \"" + booking.patron.patronNames[0] + "\"? An email will be sent to " + booking.patron.patronEmail + " with their booking confirmation." + titleMessage, 'black',
            "Cancel", 
            () => this._manualBookingConfirmApplyChanges(title),
            "Confirm",
            'green');
    }

    _manualBookingConfirmApplyChanges = (title) => {

        const booking = this.state.slotToEdit;
        booking.title = title;
        this.incrementBusy();
        this.secureJSONFetch("/bk/slots", {method: 'POST', body: this.state.slotToEdit.toJsonForPost()}, 
                            (response) => { 
                                const didModify = this._slotModifiedCallback(response); 
                                if (didModify)
                                    this._manualBookingConfirmPost(booking.id);
                            },
                            this._fetchErrorCallback);
    }

    _manualBookingConfirmPost = (bookingId) => {
        this.incrementBusy();
        console.log("posting")
        setTimeout(() => this.secureJSONFetch("/bk/slots/" + bookingId + "/confirmBooking", {method: 'POST'},
                            this._manualBookingConfirmPostCallback, this._fetchErrorCallback)); 
    }

    _manualBookingConfirmPostCallback = (response) => {
        this.decrementBusy();
        if (response) {
            const slot = Constructors.slotFromJson(response, this.state.timezone);
            if (slot) {
                if (this._calendarRef.current) {
                    this._calendarRef.current.upsertSlot(slot);
                }
            }
            this.showConfirmAlert("Success", "Booking confirmed for \"" + slot.patron.patronNames[0] + "\". Patron will be sent an email with their confirmation.", 'green');
        }
    }

    _manageSignups = () => {
        const slot = this.state.slotToEdit;

        if (!this._slotPriorToEdit) {  // should never happen
            console.error("No slot prior to edit, cannot manage signups");
            return;
        }

        if (slot.toJsonForPost() !== this._slotPriorToEdit.toJsonForPost()) {   
            this.showConfirmAlert("Discard Changes and Manage Signup", 
                "You have unsaved changes. Do you want to discard them and go to the Manage Signup page?", 'black',
                "No, keep working", 
                this._confirmManageSignups,
                "Discard changes and Manage Signups",
                'red');
        }
        else 
            this._confirmManageSignups();
    }

    _confirmManageSignups = () => {
        const slot = this.state.slotToEdit;
        this._cancelEditSlot();
        this.props.manageEventSignupCallback(slot.id);
    }


    _eventOwnerChange = () => {
        // fetch available users
        this.incrementBusy();
        this.secureJSONFetch("/bk/" + Global.getLastDatabase() + "/events/users", {}, this._fetchEventUsersCallback, this._fetchErrorCallback); 
    }
    
    _fetchEventUsersCallback = (response) => {
        this.decrementBusy();
        //response is an array of user objects, with name and id fields
        this.setState({availableEventOwners: response, selectEventOwnerPopoverOpen: true});
    }
    
    // Callback from the selectEventOwnerPopover when the user selects a new Event owner, update the event owner - "owner" is the same object as returned from fetchEventUsersCallback
    _updateEventOwner = (owner) => {
        const slot = this.state.slotToEdit;
        slot.owner = owner.id;      // set the new owner
        slot.ownerName = owner.name;
        this.setState({selectEventOwnerPopoverOpen: false});
    }

    _copyLastSelectedSlot = () => {
        if (this._lastClickedSlot) {
            this._slotToCopy = this._lastClickedSlot.copy();    // make a copy, in case the original is deleted
            this._lastSelectedDate = null;      // clear the last selected time, let the user select a new time where they want to paste
            console.log("Copied slot: " + this._slotToCopy.title);
        }
    }

    _pasteLastSelectedSlot = () => {
        if (this._slotToCopy) {   
            const slotToPaste = Slot.newFrom(this._slotToCopy);     // slot copy with new ID

            // if there is a last selected time, we will use it as the new time, otherwise it will be duplicated in place
            if (this._lastSelectedDate) {

                // when the user pastes in a month or year view, they're not picking a time, they're picking a date. We will use the same slot start time, but adjust the date
                if (this._lastSelectedDate.monthOrYearSelect)
                    slotToPaste.start = this._lastSelectedDate.start.set({hour: this._slotToCopy.start.hour, minute: this._slotToCopy.start.minute, second: 0});
                else   // in week or day view, we assume they are picking the start time.
                    slotToPaste.start = this._lastSelectedDate.start;
                
                // in both cases, we'll keep the duration the same
                slotToPaste.end = slotToPaste.start.plus({seconds: this._slotToCopy.duration()});

            }

            this._editSlot(null, slotToPaste);      // edit the new pasting slot
        }
    }

    // Delete the last selected slot, if there is one.
    _deleteLastSelectedSlot = () => {
        if (this._lastClickedSlot) {
            this.setState({slotToEdit: this._lastClickedSlot});
            this._deleteSlot();
        }
    }

    // Ok callback from EditBookingPatronsPopover, update the selected booking with the new patrons
    _updateBookingPatrons = (email, phone, enableSms, patrons) => {
        const booking = this.state.slotToEdit;
        booking.patron.patronEmail = email;
        booking.patron.patronPhone = phone;
        booking.patron.enableSms = enableSms;
        booking.patron.patronNames = [...patrons];
        booking.patron.patronCount = patrons.length;
        this.setState({editBookingPatronPopoverOpen: false});
    }


    _downloadSlotAsICal = () => {
        const slot = this.state.slotToEdit;
        const filename = slot.id + ".ics";
        this.secureFileDownload("/bk/slots/" + slot.id + "/ical", filename, null, this._fetchErrorCallback); 
    }


    //--------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------- CALENDAR OPTIONS ------------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------------

    _updateHiddenSlots = (show) => {
        setTimeout(() => {if (this._calendarRef.current)
                                    this._calendarRef.current.updateHiddenSlots();
                                   });
    }

    _changeGridSlotDuration = (duration) => {
        if (this._calendarRef.current)
            this._calendarRef.current.setGridSlotDuration(duration);
    }


    //--------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------------- CALENDAR CALLBACK -----------------------------------------------------------
    //--------------------------------------------------------------------------------------------------------------------------

    _fetchSlots = (jsonDateStart, jsonDateEnd, resourceIDs, successCallback, failureCallback) => {

        const resourceIDString = resourceIDs.join(",");     // convert the array of resource IDs to a comma separated string

        this.secureJSONFetch("/bk/slots?resourceIDs=" + resourceIDString + "&fromDate=" + jsonDateStart + "&toDate=" + jsonDateEnd, {}, 
                             (response) => successCallback(response), (error) => failureCallback(error)); 

    }

    // callback from BookingCalendar when the user clicks the add button
    _addSlotRequested = (start, end, resource, isMonthOrYearView) => {
        this._selectedStart = start;
        this._selectedEnd = end;
        this._selectedResource = resource;
        this._isAddMonthOrYearView = isMonthOrYearView;
        this.setState({newSlotSelectionPopoverOpen: true});
    }


    // callback from BookingCalendar when the user clicks on one or more slots. We wait for a double click to edit the slot. If 
    // more than one slot is clicked, show a popover to select one
    _slotsClicked = (slots) => {

        if (slots.length === 1) {
            const clickedSlot = slots[0];
            if (this._lastClickedSlot && this._lastClickedSlot.id !== clickedSlot.id)
                this._slotClickTime = null;     // different slot clicked, reset the click time

            this._lastClickedSlot = clickedSlot;       // hold onto the last clicked slot for copy/paste
            if (this._calendarRef.current)
                this._calendarRef.current.highlightSlot(this._lastClickedSlot);     // highlight the slot clicked
            console.log("Selected slot: " + this._lastClickedSlot.title);
        }

        // No first click or more than 500ms since last click? ignore and set the new click time
        if (!this._slotClickTime || Date.now() - this._slotClickTime > 500) {
            this._slotClickTime = Date.now();
            this._clickedSlots = [];
            return;
        }
        this._clickedSlots = slots;
        this._slotClickTime = null;

        if (slots.length === 0)
            return;
        else if (this._clickedSlots.length > 1)
            this.setState({slotSelectionPopoverOpen: true});
        else {
            const originalSlot = this._clickedSlots[0];
            this._editSlot(originalSlot, originalSlot.copy());    // edit the clicked slot, making a copy to keep the original
        }
    }

    _slotSelectionCancelled = () => {
        this._clickedSlots = [];
        this.setState({slotSelectionPopoverOpen: false});
        if (this._calendarRef.current)
            this._calendarRef.current.highlightSlot(null);     // clear the highlighted slot
        console.log("Selected slot: " + this._lastClickedSlot.title);
    }   

    // callback from SlotSelectionPopover when the user selects a slot (from multiple overlapping slots clicked), call _editSlot with the selected slot
    _slotSelected = (slotId) => {
        const selectedSlot = this._clickedSlots.find((slot) => slot.id === slotId);
        this._clickedSlots = [];
        this.setState({slotSelectionPopoverOpen: false});
        if (selectedSlot) {
            this._editSlot(selectedSlot, selectedSlot.copy());    // edit the selected slot, making a copy to keep the original
            if (this._calendarRef.current)
                this._calendarRef.current.highlightSlot(selectedSlot);
        }
    }

    // Callback from BookingCalendar when the user selects a date range, if the user double clicks, add a new slot.  Resource is only valid when clicked in the Timeline view
    _dateSelected = (startTime, endTime, resource, isMonthOrYearView) => {
        this._lastSelectedDate = {start: startTime, end: endTime, monthOrYearSelect: isMonthOrYearView};

        if (this._calendarRef.current)
            this._calendarRef.current.highlightSlot(null);     // user clicked off any selected slot, clear the highlighted slot

        // See if this was a double click
        if (this._dateSelectTime && Date.now() - this._dateSelectTime < 500) {
            console.log("Double click: ", this._dateSelectTime);
            this._dateSelectTime = null;
            this._addSlotRequested(startTime, endTime, resource, isMonthOrYearView);
        }
        
        this._dateSelectTime = Date.now();
        console.log("Selected time " + (isMonthOrYearView ? "in month or year view: " : "in day or week view: ") + startTime.toISO() + " to " + endTime.toISO() + 
                    (resource ? " in resource " + resource.name : ""));
    }

    // callback from BookingCalendar when the user changes the date range, by dragging it or resizing it
    _slotChangedByDragOrResize = (slot, newStart, newEnd, resourceChange) => {

        if (this._calendarRef.current)
            this._calendarRef.current.highlightSlot(null);     // user working with a different slot, clear the highlighted slot

        const slotCopy = slot.copy();     // make a copy of the slot so we don't modify the original, in case we need to cancel

        // Adjust the copy's start and end times to match what the user dragged or resized
        slotCopy.start = newStart;
        slotCopy.end = newEnd;

        if (resourceChange) {       // slot was dragged to a new Resource
            const oldResource = this.state.resources.find(r => r.id === resourceChange.oldResourceId);
            const newResource = this.state.resources.find(r => r.id === resourceChange.newResourceId);

            if (oldResource.type !== newResource.type) {
                this.showConfirmAlert("Error", "Cannot move a " + slotCopy.type + " from a " + oldResource.type + " to a " + newResource.type, 'red');
                if (this._calendarRef.current)        // revert change to the original
                    this._calendarRef.current.upsertSlot(slot);
                return;
            }

            if (newResource instanceof Service && !newResource.canManage()) {
                this.showConfirmAlert("Error", "You do not have permission to change this item's Service to \"" + newResource.name + "\" (you are not the Service owner)", 'red');
                if (this._calendarRef.current)        // revert change to the original
                    this._calendarRef.current.upsertSlot(slot);
                return;
            }

            // Replace the old Resource id with the new one in the slot
            slotCopy.replaceResourceId(resourceChange.oldResourceId, resourceChange.newResourceId);
        }

        this._originalSlotBeingEdited = slot;    // save the original slot, in case we need to revert
        this._editSlot(slot, slotCopy, true);
    }

    _calendarOptionClicked  = (buttonElement) => {
        this.setState({calendarOptionsPopoverOpen: true, calendarOptionsPopoverAnchor: buttonElement});
    }

    _calendarBusy = (busy) => {
        if (busy)
            this.incrementBusy();   // here we can just incrememnt because other things may also be busy
        else
            this.setBusy(false);    // we expect to be the last busy thing, this will make sure it stays off
    }   


    /**
     * For either the Resources or Services section, create a DragDropContext with a Droppable for each category, and a Draggable for each resource. This lets
     * the user drag and drop resources to change their category, or to reorder them within a category. 
     */
    _resourceDnD = (categories) => {
        return <DragDropContext onDragEnd={this._resourceDragEnd}>
                    <div style={{padding: 5}}>
                        {categories.map((category, index) =>
                            <Droppable droppableId={"CATEGORY" + category[0].category} key={index}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <CategoryGroup key={index} categoryName={category[0].category}>
                                            {category.map((resource, index2) => 
                                                <Draggable draggableId={resource.name} index={index2} key={index2}>
                                                    {(provided, snapshot) => (
                                                        <div ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps}>  
                                                            {this._resourceButton(resource, index2)}
                                                        </div>
                                                    )}  
                                                </Draggable>
                                            )}  
                                            {provided.placeholder}
                                        </CategoryGroup>
                                    </div>                                    
                                )}
                            </Droppable>
                        )}
                    </div>
                </DragDropContext>
    }



    render() {

        // If there are resources, move the busy spinner to be next to the + button in the calendar (not for small screens)
        const busyStyle = this.state.resources.length > 0 && window.innerWidth > 880 ? {position: 'absolute', zIndex: 1, transform: 'translate(250px, 6px)'} : {marginBottom: 20};

        // Break up the resources into categories
        const resourceCategories = Resource.groupResourcesByCategory(this.state.resources, "Resource");
        const serviceCategories =  Resource.groupResourcesByCategory(this.state.resources, "Service");

        const resourceForNewSlots = this._selectedResource ? this._selectedResource : this.state.selectedResource;

        return (
            <Fragment>
                {this.getConfirmAlertComponent()}  

                <NewEditResourcePopover isOpen={this.state.newEditResourcePopoverOpen} isNew={this.state.createNewResource} resource={this.state.selectedResource} 
                                        cancelCallback={() => this.setState({newEditResourcePopoverOpen: false})} okCallback={this._createEditNewResourceCallback}/>

                <NewSlotSelectionPopover isOpen={this.state.newSlotSelectionPopoverOpen} 
                                        resources={this.state.resources} selectedResource={resourceForNewSlots}
                                        cancelCallback={this._cancelAddSlot} 
                                        okCallback={this._addSlot}/>

                <SlotSelectionPopover isOpen={this.state.slotSelectionPopoverOpen} slots={this._clickedSlots}
                                      cancelCallback={this._slotSelectionCancelled} okCallback={this._slotSelected}/>

                <EditSlot isOpen={this.state.editSlotPopoverOpen} slot={this.state.slotToEdit} addingNew={this.state.slotToEditIsNew} recurrenceExists={this.state.recurrenceExists}
                          timezone={this.state.timezone} resources={this.state.resources} onUpdate={this._slotModified} onCancel={this._cancelEditSlot} onDelete={this._deleteSlot}
                          onOwnerChange={this._eventOwnerChange} onBookingPatronEdit={this._bookingPatronEdit} onManualBookingConfirm={this._manualBookingConfirm} onManageSignups={this._manageSignups}
                          onDownloadAsICal={this._downloadSlotAsICal}/>   

                <RecurrenceQuestionDialog isOpen={this.state.recurrenceQuestionOpen} slot={this.state.slotToEdit} isDeletingSlot={this.state.isDeletingSlot}
                                         willRegenerateRecurrence={this.state.willRegenerateRecurrence} timezone={this.state.timezone}
                                         recurringActiveCount={this.state.recurringActiveCount}
                                         justThisOneSelected={this._slotRecurrenceJustThisOne} thisAndFutureSelected={this._slotRecurrenceThisAndFuture} allSelected={this._slotRecurrenceAll} cancelSelected={() => this.setState({recurrenceQuestionOpen: false})}/>


                <AssignResourcesPopover isOpen={this.state.assignServiceResourcesPopoverOpen} service={this.state.selectedResource} resources={this.state.resources}
                                        onCancel={() => this.setState({assignServiceResourcesPopoverOpen: false})} onUpdate={this._serviceAssignedResources}/>

                <CalendarOptionsPopover isOpen={this.state.calendarOptionsPopoverOpen} anchor={this.state.calendarOptionsPopoverAnchor} onClose={() => this.setState({calendarOptionsPopoverOpen: false})}
                                        updateHiddenSlots={this._updateHiddenSlots} onChangeGridSlotDuration={this._changeGridSlotDuration}/>

                {this.state.selectedResource ?
                    <ListSelectPopover isOpen={this.state.selectServiceOwnerPopoverOpen} title={"Select New Service Owner for " + this.state.selectedResource.name}  
                                        items={this.state.availableServiceOwners}
                                        okCallback={(selected) => this._updateServiceOwner(selected)}
                                        noneCallback={() => this._updateServiceOwner(null)}
                                        cancelCallback={() => this.setState({selectServiceOwnerPopoverOpen: false})}/>      
                    : null
                }     

                {this.state.slotToEdit && this.state.slotToEdit instanceof CalEvent ?
                    <ListSelectPopover isOpen={this.state.selectEventOwnerPopoverOpen} title={"Select New Event Owner for " + this.state.slotToEdit.title}  
                                        items={this.state.availableEventOwners}
                                        okCallback={(selected) => this._updateEventOwner(selected)}
                                        cancelCallback={() => this.setState({selectEventOwnerPopoverOpen: false})}/>      
                        : null
                }   

                {this.state.slotToEdit && this.state.slotToEdit instanceof Booking && this.state.editBookingPatronPopoverOpen ?
                    <EditPatronPopover  patron={this.state.slotToEdit.patron}
                                        okCallback={this._updateBookingPatrons}
                                        cancelCallback={() => this.setState({editBookingPatronPopoverOpen: false})}/>
                    : null
                }

                <Grid container direction="row" spacing={2} style={{padding: 10}}>

                    {/* ---------------------------------------- LEFT PANE: RESOURCE CONTROL ------------------------------------------------ */}
                    <Grid className="no-print" item xs={12} md={2}> 
                        <div style={{display: 'flex', flexDirection: 'column', gap: 10, justifyContent: 'flex-start', height: '100%', maxWidth: 320}}>
                            
                            {/* ------- Resource and Service Select ------- */}
                            <Paper>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Typography variant="body1" align='left' style={this.styles.paperLabel}>Resources</Typography>
                                    <Button size='small' onClick={() => this._selectAllResources("Resource")} style={{color: 'gray', fontSize: '8pt', marginTop: 2}} component="label">✓ All</Button>
                                </div>

                                {this._resourceDnD(resourceCategories)}
                                
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
                                    <Typography variant="body1" align='left' style={this.styles.paperLabel}>Services</Typography>  
                                    <Button size='small' onClick={() => this._selectAllResources("Service")} style={{color: 'gray', fontSize: '8pt', marginTop: 2}} component="label">✓ All</Button>
                                </div>

                                {this._resourceDnD(serviceCategories)}

                                {Global.isResourceManager() ?
                                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, padding: 10}}>
                                        <Tooltip title="New Resource or Service">
                                            <Button size='small' fullWidth onClick={() => this.setState({newEditResourcePopoverOpen: true, createNewResource: true})} variant="outlined" style={{color: 'orange'}} component="label">
                                                New...
                                            </Button>
                                        </Tooltip>
                                    </div>
                                : null
                                }

                                <div style={{height: 10}}></div>
                            </Paper>
                            
                            {/* ------- Selected Resource Control ------- */}

                            {this.state.selectedResource ? 
                                <Paper style={{marginTop: 20, marginBottom: 20}}>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: 10, justifyContent: 'center', padding: 10, maxWidth: 320}}>
                                        
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10, gap: 10}}>
                                            <Typography variant="h6" align='left' style={{color: ThemeColors.appBarBackground}}>{this.state.selectedResource.name}</Typography>
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'right', alignItems: 'right'}}>
                                                {Global.isResourceManager() ?
                                                    <Tooltip title="Edit Name, Category, or Description">
                                                        <Button size='small' onClick={() => this.setState({newEditResourcePopoverOpen: true, createNewResource: false})} 
                                                                style={{color: 'gray', fontSize: '8pt'}} component="label">Edit</Button>
                                                    </Tooltip>
                                                    : null
                                                }
                                                {this.state.selectedResource instanceof Service ?
                                                    <Tooltip title="Manage this Service on the Services Page">
                                                        <Button size='small' onClick={() => this.props.manageServiceCallback(this.state.selectedResource.id)} 
                                                                style={{color: 'gray', fontSize: '8pt'}} component="label" endIcon={<ReplyIcon style={{transform: 'scaleX(-1)'}}/>}>Manage</Button>
                                                    </Tooltip>
                                                    : null
                                                }
                                            </div>
                                        </div>

                                        <ResourceColorActions resource={this.state.selectedResource} onChange={this._updateResource} resetColorCallback={this._resetSlotColorsForResource}/>

                                        {this.state.selectedResource instanceof Service ?
                                            <Fragment>
                                                {Global.isResourceManager() ?
                                                    <Tooltip title="Select new Service owner">
                                                        <Button size='small' variant='outlined' style={{color: this.state.selectedResource.ownerName ? 'black' : 'red'}} onClick={this._selectServiceOwner}>
                                                            {"Service Owner: " + (this.state.selectedResource.ownerName ? this.state.selectedResource.ownerName : "Unassigned")}
                                                        </Button>
                                                    </Tooltip>
                                                    :
                                                    <Typography variant="body2" align='center' style={{marginBottom: 5, color: this.state.selectedResource.ownerName ? 'black' : 'red'}}>
                                                        {"Service Owner: " + (this.state.selectedResource.ownerName ? this.state.selectedResource.ownerName : "Unassigned")}
                                                    </Typography>  
                                                }
                                            </Fragment>
                                            : null
                                        }

                                        {this.state.selectedResource instanceof Service ?
                                            <Fragment>
                                                <Button size='small' disabled={!this.state.selectedResource.canEdit()} onClick={this._selectAssignableResourcesForService} variant="outlined" 
                                                        style={{color: this.state.selectedResource.canEdit() ? (this.state.selectedResource.assignableResourceIds.length > 0 ? 'blue': 'red') : 'lightGray'}} component="label">
                                                    {"Assign Resources (" + this.state.selectedResource.assignableResourceIds.length + ")"}
                                                </Button>
                                            </Fragment>
                                            : null
                                        }
                                        <Button size='small' disabled={!this.state.selectedResource.canEdit()} onClick={this._deleteResource} variant="outlined" style={{color: this.state.selectedResource.canEdit() ? 'red' : 'lightGray'}} component="label">
                                            Delete
                                        </Button>
                                    </div>
                                
                                </Paper>
                                : null
                            }

                            {/* ---------- Timezone ---------- */}

                            <div style={{flexGrow: 1}}/>
                            {Global.isResourceManager() ?
                                <Autocomplete
                                    size='small'
                                    value={this.state.timezone}
                                    onChange={(event, newValue) => { this._updateTimezone(newValue); }}
                                    inputValue={this.state.timezoneValue}
                                    onInputChange={(event, newValue) => { this.setState({timezoneValue: newValue}); }}
                                    options={this._luxonValidZones}
                                    blurOnSelect
                                    clearOnEscape={false}
                                    clearIcon={null}
                                    renderInput={(params) => <TextField {...params} label="Timezone" variant="outlined" InputLabelProps={{ shrink: true }} />}
                                /> 
                                :
                                <div>
                                    <Typography variant="body2" align='center' style={this.styles.paperLabel}>Timezone</Typography>  
                                    <Typography variant="body2" align='center' style={{fontStyle: 'italic', marginTop: 5}}>{this.state.timezone}</Typography>  
                                </div>  
                            }
                            <Paper style={{marginTop: 5}}>
                                <Typography variant="body1" align='left' style={{marginLeft: 10, color: 'gray', fontSize: '10pt'}}>Calendar Links</Typography>
                                <Button size='small' style={{fontSize: '8pt', margin: 2}} onClick={this._getIcalSubscription} component="label">iCal Internal</Button>
                                <Button size='small' style={{fontSize: '8pt', margin: 2}} onClick={this._getPublicIcalSubscription} component="label">iCal Public</Button>
                                <Button size='small' style={{fontSize: '8pt', margin: 2}} onClick={this._getPatronBookingLink} component="label">Patron Booking</Button>
                                <Button size='small' style={{fontSize: '8pt', margin: 2}} onClick={this._getEventSignupLink} component="label">Event Signups</Button>
                            </Paper>

                             
                        </div>
                    </Grid>

                    {/* ---------------------------------------- RIGHT PANE: CALENDAR ------------------------------------------------ */}

                    <Grid item xs={12} md={10}>
                        <div style={busyStyle}>
                            {this.state.isBusy ? this.getBusyComponent('left', {}, 22) : <div style={{height: 22}}/>}
                        </div>

                        {this.state.didInitialResourceFetch ?
                            (this.state.resources.length === 0 ?
                                <Typography variant="h6" style={{textAlign: 'center', marginTop: 80}}>{Global.isResourceManager() ? "Add a new Resource or Service to get started" : "There are no Resources to show"}</Typography>
                                :
                                <BookingCalendar ref={this._calendarRef} 
                                                key={this.state.timezone /* force re-render when timezone changes, to fix the "today" indication for the new timezone */}  
                                                initialTimezone={this.state.timezone} 
                                                gridSlotDuration={Global.getGridSlotDuration()} 
                                                resources={this.state.checkedResources ? this.state.checkedResources : []}
                                                fetchSlots={this._fetchSlots}
                                                addCallback={this._addSlotRequested}
                                                slotClickCallback={this._slotsClicked}
                                                dateSelectCallback={this._dateSelected}
                                                slotChangeCallback={this._slotChangedByDragOrResize}
                                                optionsClickCallback={this._calendarOptionClicked}
                                                loadingCallback={this._calendarBusy}
                                                initialHighlightedSlot={this.props.highlightedSlotId}
                                />
                            ) : null
                        }
                    </Grid>
                </Grid>

            </Fragment> 
        );
    }
}
  


export default withCookies(withRouter(CalendarPage));

